<template>
  <div>
    <div class="grid grid-flow-col grid-cols-2 grid-rows-3 gap-1">
      <BarChart />
      <DognutChart />
      <RadarChart />
      <PieChart />
      <PolarChart />
      <PlanetChart />
    </div>
  </div>
</template>

<script>
import PlanetChart from "@/components/charts/planet/PlanetChart.vue";
import BarChart from "@/components/charts/bar/BarChart.vue";
import RadarChart from "@/components/charts/radar/RadarChart.vue";
import PieChart from "@/components/charts/pie/PieChart.vue";
import DognutChart from "@/components/charts/dognut/DognutChart.vue";
import PolarChart from "@/components/charts/polar/PolarChart.vue";

export default {
  components: {
    PlanetChart,
    BarChart,
    RadarChart,
    PieChart,
    DognutChart,
    PolarChart,
  },
};
</script>

<style>
</style>