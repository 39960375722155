<template>
  <div class="max-w-2xl mx-auto mt-4" v-if="invoice">
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Sales invoice
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Customer invoice with order detail.
        </p>
      </div>
      <div class="border-t border-gray-200">
        <dl>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">ID</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ invoice.id }}
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Customer name</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ invoice.customername }}
            </dd>
          </div>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Created at</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ getDateTime(invoice.created_at) }}
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Updated at</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ getDateTime(invoice.updated_at) }}
            </dd>
          </div>
        </dl>
      </div>

      <div class="mt-4" v-if="total != 0">
        <table>
          <thead>
            <tr>
              <th>SKU</th>
              <th>Quantity</th>
              <th>Unitprice</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="detail in invoice.details" :key="detail.id">
              <td>{{ getSku(detail.sku_id) }}</td>
              <td>{{ detail.qty }}</td>
              <td>{{ detail.unitprice }}</td>
              <td>{{ getAmount(detail) }}</td>
              <th>
                <a
                  @click="deletedetail(detail.id)"
                  href="#"
                  class="text-red-700 flex items-center text-sm"
                >
                  <TrashIcon
                    class="h-4 w-4 group-hover:text-indigo-400 mr-2"
                    aria-hidden="true"
                  />
                  <span>Delete</span></a
                >
              </th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Tax 5%</td>
              <td>{{ tax }}</td>
              <th></th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{{ total }}</td>
              <th></th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-4">
        <div class="px-5 py-3">
          <form action="#" @submit.prevent="add">
            <div class="flex">
              <select v-model="sku_id" class="input-left">
                <option value="">Choose..</option>
                <option v-for="sku in skus" :key="sku.id" :value="sku.id">
                  {{ sku.sku }}
                </option>
              </select>
              <input
                type="text"
                v-model="qty"
                class="input-center"
                placeholder="quantity"
              />
              <input
                type="text"
                v-model="unitprice"
                class="input-right"
                placeholder="unitprice"
              />
              <div class="ml-4">
                <button
                  type="submit"
                  class="bg-indigo-500 px-3 py-2 text-white rounded shadow"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { TrashIcon } from "@heroicons/vue/solid";

export default {
  components: {
    TrashIcon,
  },
  data() {
    return {
      sku_id: "",
      qty: "",
      unitprice: "",
    };
  },
  props: ["id"],
  created() {
    this.$store.dispatch("getSkus");
    this.$store.dispatch("invoicedetails/get", this.id);
  },
  computed: {
    tax() {
      var subtotal = 0;
      var details = this.invoice.details;
      for (let detail of details) {
        subtotal += Number(detail.qty) * Number(detail.unitprice);
      }
      return (subtotal * 0.05).toFixed(2);
    },
    total() {
      var subtotal = 0;
      var details = this.invoice.details;
      for (let detail of details) {
        subtotal += Number(detail.qty) * Number(detail.unitprice);
      }
      return (subtotal + subtotal * 0.05).toFixed(2);
    },
    ...mapState(["skus"]),
    ...mapState("invoicedetails", ["invoice"]),
  },
  methods: {
    deletedetail(id) {
      this.$store.dispatch("invoicedetails/destroy", id);
    },
    add() {
      var invoicedetail = {
        salesinvoice_id: this.id,
        sku_id: this.sku_id,
        qty: this.qty,
        unitprice: this.unitprice,
      };
      this.$store.dispatch("invoicedetails/store", invoicedetail);
    },
    getAmount(detail) {
      return (Number(detail.qty) * Number(detail.unitprice)).toFixed(2);
    },
    getSku(id) {
      var sku = this.skus.find((sku) => sku.id == id);
      return sku.sku;
    },
    getDateTime(timestamp) {
      timestamp = new Date(timestamp);
      const date =
        timestamp.getFullYear() +
        "-" +
        (timestamp.getMonth() + 1) +
        "-" +
        timestamp.getDate();
      const time =
        timestamp.getHours() +
        ":" +
        timestamp.getMinutes() +
        ":" +
        timestamp.getSeconds();
      const dateTime = date + " " + time;
      return dateTime;
    },
  },
};
</script>

<style scoped>
.input-text {
  @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}
table {
  @apply min-w-full divide-y divide-gray-200;
}
table thead {
  @apply bg-gray-50;
}
table thead tr th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider;
}
table tbody {
  @apply bg-white divide-y divide-gray-200;
}
table tbody tr td {
  @apply px-6 py-3 text-left text-sm font-medium text-gray-900 tracking-wider;
}
td.links {
  @apply inline-flex items-center space-x-4;
}
td.links a {
  @apply text-indigo-600 hover:text-indigo-900;
}
td.links form button {
  @apply text-red-600 hover:text-red-900 focus:outline-none font-medium;
}
.input-left {
  @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 rounded-none rounded-l-md sm:text-sm border-gray-300;
}
.input-right {
  @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 rounded-none rounded-r-md sm:text-sm border-gray-300;
}
.input-center {
  @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 rounded-none sm:text-sm border-gray-300;
}
</style>