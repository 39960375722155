<template>
  <div class="m-4">
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="pages-table">
            <slot></slot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>