<template>
  <div class="flex">
    <div class="flex-shrink-0">
      <div class="w-64">
        <div class="bg-gradient-to-r from-purple-400 to-blue-500">
          <div class="h-screen flex flex-col justify-between">
            <div>
              <div class="flex px-4 py-3 items-center">
                <svg
                  class="w-auto h-9 flex-shrink-0 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"
                  />
                </svg>
                <h3
                  class="text-2xl leading-6 font-bold text-gray-100 ml-4 tracking-tight"
                >
                  Wesoftware
                </h3>
              </div>
              <nav class="mt-2">
                <ul class="px-2 py-2 space-y-2">
                  <li
                    class="px-3 py-2 hover:bg-indigo-900 hover:rounded hover:shadow hover:text-gray-200"
                  >
                    <router-link
                      :to="{ name: 'Home' }"
                      href="#"
                      class="flex items-center text-white"
                    >
                      <HomeIcon
                        class="h-6 w-6 w-auto flex-shrink-0 text-white"
                        aria-hidden="true"
                      />
                      <span class="ml-4 font-medium">Home</span>
                    </router-link>
                  </li>
                  <li
                    class="px-3 py-2 hover:bg-indigo-900 hover:rounded hover:shadow hover:text-gray-200"
                  >
                    <router-link
                      :to="{ name: 'Request' }"
                      class="flex items-center"
                    >
                      <NewspaperIcon
                        class="h-6 w-6 w-auto flex-shrink-0 text-white"
                        aria-hidden="true"
                      />
                      <span class="ml-4 font-medium">Request Stock</span>
                    </router-link>
                  </li>
                  <li
                    class="px-3 py-2 hover:bg-indigo-900 hover:rounded hover:shadow hover:text-gray-200"
                  >
                    <router-link
                      :to="{ name: 'Sales' }"
                      href="#"
                      class="flex items-center"
                    >
                      <ShoppingCartIcon
                        class="h-6 w-6 w-auto flex-shrink-0 text-white"
                        aria-hidden="true"
                      />
                      <span class="font-medium ml-4">Sales Invoice</span>
                    </router-link>
                  </li>
                  <li
                    class="px-3 py-2 hover:bg-indigo-900 hover:rounded hover:shadow hover:text-gray-200"
                  >
                    <router-link
                      :to="{ name: 'Transfer' }"
                      href="#"
                      class="flex items-center"
                    >
                      <SwitchVerticalIcon
                        class="h-6 w-6 w-auto flex-shrink-0 text-white"
                        aria-hidden="true"
                      />

                      <span class="font-medium ml-4">Stock Transfer</span>
                    </router-link>
                  </li>
                  <li
                    class="px-3 py-2 hover:bg-indigo-900 hover:rounded hover:shadow hover:text-gray-200"
                  >
                    <router-link
                      :to="{ name: 'Return' }"
                      href="#"
                      class="flex items-center"
                    >
                      <ReplyIcon
                        class="h-6 w-6 w-auto flex-shrink-0 text-white"
                        aria-hidden="true"
                      />

                      <span class="font-medium ml-4">Stock Return</span>
                    </router-link>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="border-t border-indigo-500 border-opacity-30">
              <ul class="px-2 py-2">
                <li class="px-3 py-2 flex items-center text-gray-300">
                  <svg
                    class="flex-shrink-0 h-10 w-10 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <div class="ml-4">
                    <h2
                      class="font-medium text-gray-200 leading-4 tracking-wide"
                    >
                      User Name
                    </h2>

                    <form
                      @submit.prevent="logout"
                      action=""
                      method="POST"
                      class="inline-flex"
                    >
                      <button
                        type="submit"
                        class="text-sm text-gray-300 font-medium hover:text-white focus:outline-none"
                      >
                        Logout
                      </button>
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="h-screen bg-indigo-100">
        <div class="flex flex-col h-full">
          <div class="flex-auto overflow-y-auto no-scrollbar">
            <slot> </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  HomeIcon,
  NewspaperIcon,
  ShoppingCartIcon,
  SwitchVerticalIcon,
  ReplyIcon,
} from "@heroicons/vue/outline";

export default {
  components: {
    HomeIcon,
    NewspaperIcon,
    ShoppingCartIcon,
    SwitchVerticalIcon,
    ReplyIcon,
  },
  methods: {
    logout() {
      this.logout();
    },
    ...mapActions("user", ["logout"]),
  },
  computed: mapGetters("user", ["loggedIn"]),
};
</script>

<style scoped>
nav ul li a span {
  @apply text-red-50 text-white font-medium;
}
</style>