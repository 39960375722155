import EventServices from '@/services/EventServices.js'
export const namespaced = true

export const state ={
    requests:[],
    request:null,
}

export const mutations={
    SET_REQUESTS(state,requests){
        state.requests = requests
    },
    ADD_REQUEST(state,request){
        state.requests.push(request)
    },
    SET_REQUEST(state,request){
        state.request=request
    },
    DELETE_REQUEST(state,id){
        state.requests = state.requests.filter(request=>request.id!==id)
    },
}

export const actions={
    get({commit}){
        return EventServices.api().get('/requeststocksnew').then((response)=>{
            console.log("Action received response that is",response.data)
            commit('SET_REQUESTS',response.data)
        })
    },
    store({commit},request){
        return EventServices.api().post('/requeststocksnew/',request).then((response)=>{
            commit('ADD_REQUEST',response.data)
            commit('SET_REQUEST',response.data)
        })
    },
    show({commit},id){
        commit('SET_REQUEST',null)
        return EventServices.api().get('/requeststockorders/'+id).then((response)=>{
            commit('SET_REQUEST',response.data)
        })
    },
    destroy({commit},id){
        return EventServices.api().delete('/requeststockorders/'+id).then(()=>{
            commit('DELETE_REQUEST',id)
        })
    },
}

export const getters = {

}