import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";

import Request from "../views/requests/Index.vue";
import RequestAdd from "../views/requests/Add.vue";
import RequestEdit from "../views/requests/Edit.vue";
import RequestEditDetail from "../views/requests/EditDetail.vue";

import Sales from "../views/sales/Index.vue";
import SalesAdd from "../views/sales/Add.vue";
import SalesEdit from "../views/sales/Edit.vue";
import SalesShow from "../views/sales/Show.vue";

import Transfer from "../views/transfers/Index.vue";
import Return from "../views/returns/Index.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta:{requiresAuth:true},
  },
  {
    path: "/request",
    name: "Request",
    component: Request,
    meta:{requiresAuth:true},
  },
  {
    path: "/request/add",
    name: "RequestAdd",
    component: RequestAdd,
    meta:{requiresAuth:true},
  },
  {
    path: "/request/edit/:id",
    name: "RequestEdit",
    component: RequestEdit,
    meta:{requiresAuth:true},
    props:true
  },
  {
    path: "/request/edit/detail/:id",
    name: "RequestEditDetail",
    component: RequestEditDetail,
    meta:{requiresAuth:true},
    props:true
  },
  {
    path: "/sales",
    name: "Sales",
    component: Sales,
    meta:{requiresAuth:true},
  },
  {
    path: "/sales/add",
    name: "SalesAdd",
    component: SalesAdd,
    meta:{requiresAuth:true},
  },
  {
    path: "/sales/show/:id",
    name: "SalesShow",
    component: SalesShow,
    meta:{requiresAuth:true},
    props:true
  },
  {
    path: "/sales/edit/:id",
    name: "SalesEdit",
    component: SalesEdit,
    meta:{requiresAuth:true},
    props:true
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: Transfer,
    meta:{requiresAuth:true},
  },
  {
    path: "/return",
    name: "Return",
    component: Return,
    meta:{requiresAuth:true},
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to,from,next)=>{
  const loggedIn = localStorage.getItem('user')
  if(to.matched.some(record=>record.meta.requiresAuth) && !loggedIn){
    next('/')
  }
  next()
})

export default router;
