import { createStore } from "vuex";
import EventServices from '@/services/EventServices.js'
import * as user from '@/store/modules/user.js'
import * as request from '@/store/modules/request.js'
import * as invoices from '@/store/modules/invoice/invoice.js'
import * as invoicedetails from '@/store/modules/invoice/detail.js'
import * as salesinvoice from '@/store/modules/salesinvoice/salesinvoice.js'
import * as requeststock from '@/store/modules/requeststock/request.js'


export default createStore({
  state: {
    skus:[],
  },
  mutations: {
    SET_SKUS(state,skus){
      state.skus=skus
    },
  },
  actions: {
    getSkus({commit}){
      return EventServices.getSkus().then((response)=>{
          commit('SET_SKUS',response.data)
      })
    },
  },
  modules: {
    user,
    request,
    invoices,
    invoicedetails,
    salesinvoice,
    requeststock,
  },
});
