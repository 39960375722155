import EventServices from '@/services/EventServices.js'

export const namespaced = true

export const state={
    user:null,
}

export const mutations={
    SET_USER(state,user){
        state.user = user
        localStorage.setItem('user',JSON.stringify(user))
        EventServices.setToken(user.token)
    },
    CLEAR_USER(){
        localStorage.removeItem('user')
        location.reload()
    }
}

export const actions = {
        login({commit},user){
        return EventServices.loginUser(user)
        .then((response)=>{
            commit('SET_USER',response.data)
            console.log(response.data.token)
        })
    },
    logout({commit}){
        commit('CLEAR_USER')
    }
}

export const getters={
    loggedIn(state){
      return !!state.user
    },
    userID(state){
        return state.user.user.id
    }
  }