<template>
  <div>
    <div class="flex items-center">
      <div class="px-5 py-3">
        <div class="space-x-3">
          <router-link
            :to="{ name: 'RequestAdd' }"
            class="btn-primary bg-gradient-to-r from-purple-400 to-blue-500"
            >New Request Stocks</router-link
          >
        </div>
      </div>
    </div>

    <ContainersPage>
      <Table v-if="requests">
        <thead>
          <tr>
            <th>ID</th>
            <th>Region</th>
            <th>City</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="request in requests" :key="request.id">
            <td>{{ request.id }}</td>
            <td>{{ request.region }}</td>
            <td>{{ request.city }}</td>
            <td class="links">
              <router-link
                :to="{ name: 'RequestEdit', params: { id: request.id } }"
                >Edit</router-link
              >
              <form action="#">
                <button type="button" @click="submit(request.id)">
                  Delete
                </button>
              </form>
            </td>
          </tr>
        </tbody>
      </Table>
    </ContainersPage>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContainersPage from "@/components/containers/ContainersPage";
import Table from "@/components/containers/Table";

export default {
  name: "Request",
  components: {
    ContainersPage,
    Table,
  },
  created() {
    this.$store.dispatch("requeststock/get").then(() => {
      console.log("Response received", this.requests);
    });
  },
  computed: mapState("requeststock", ["requests"]),
  methods: {
    submit(id) {
      //this.deleteRequest(id);
      this.$store.dispatch("requeststock/destroy", id);
      //console.log("received delete request", id);
    },
  },
};
</script>

<style scoped>
.btn-primary {
  @apply px-3 py-2 text-gray-200 font-medium bg-indigo-700 hover:text-white hover:bg-indigo-600 rounded focus:outline-none;
}
</style>