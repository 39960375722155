export const barChartData = {
  type: 'radar',
  data: {
    labels: ['Sales', 'Purchases', 'Expense', 'Others'],
    datasets: [
        {
            data: [20, 10, 4, 2]
        },
        {
            data: [30, 45, 55, 25]
        },
        {
            data: [60, 85, 55, 95]
        }
    ]
  },
  options: {
    scale: {
            angleLines: {
                display: false
            },
            ticks: {
                suggestedMin: 50,
                suggestedMax: 100
            }
        }
    }
};
  
export default barChartData;