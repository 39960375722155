export const barChartData = {
  type: 'doughnut',
  data: {
    labels: ['Profit', 'Invoice', 'Expense'],
    datasets: [
        {
            data: [10, 20, 30],
            backgroundColor: [
              'rgba(215, 99, 132, 0.9)',
              'rgba(54, 112, 235, 0.9)',
              'rgba(215, 206, 86, 0.9)',
              
            ]
        }
    ]
  }
};
  
export default barChartData;