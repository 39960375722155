<template>
  <Model>
    <div class="body">
      <div class="bg-purple-blue">
        <div class="px-3 py-2">
          <h2 class="text-xl text-white font-bold">Create Invoice</h2>
        </div>
      </div>
      <div class="overflow-y-auto no-scrollbar max-h-96">
        <!-- Top Sectionn -->
        <div class="md:grid md:grid-cols-4 md:gap-6">
          <div class="md:col-span-2">
            <div class="px-4 py-5">
              <div class="grid grid-cols-4 gap-1">
                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Invoice ID(Auto)</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    value="**"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Date</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="today"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Time</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="now"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Salesman</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="salesperson"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Location</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="location"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Region</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="region"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >City</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="city"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="px-4 py-5">
              <div class="grid grid-cols-4 gap-1">
                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Customer Type</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <select
                    v-model="customertype"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="Individual">Individual</option>
                    <option value="Trader">Trader</option>
                  </select>
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Customer Name</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    type="text"
                    v-model="customername"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Address</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    type="text"
                    v-model="address"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Invoice Type</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <select
                    v-model="invoicetype"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="Cash">Cash</option>
                    <option value="Account">Account</option>
                  </select>
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Credit balance</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    type="text"
                    v-model="creditbalance"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Bandle Code</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    type="text"
                    v-model="bundlecode"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Bottom Section -->
        <div class="md:grid md:grid-cols-4 md:gap-6">
          <div class="md:col-span-2">
            <div class="px-4 py-5">
              <div
                class="grid grid-flow-row grid-cols-4 grid-rows-1 data-table"
              >
                <input
                  disabled
                  type="text"
                  value="SKU"
                  class="focus:ring-indigo-500 text-gray-700 font-bold focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border-r-0 bg-indigo-100"
                />
                <input
                  disabled
                  type="text"
                  value="Quntity"
                  class="focus:ring-indigo-500 text-gray-700 font-bold focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border-r-0 bg-indigo-100"
                />
                <input
                  disabled
                  type="text"
                  value="Unitprice"
                  class="focus:ring-indigo-500 text-gray-700 font-bold focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border-r-0 bg-indigo-100"
                />
                <input
                  disabled
                  type="text"
                  value="Amount"
                  class="focus:ring-indigo-500 text-gray-700 font-bold focus:border-indigo-500 block w-full sm:text-sm border-gray-300 bg-indigo-100"
                />
                <select
                  v-model="sku1"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty1"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice1"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount1"
                  class="block w-full sm:text-sm border-gray-300"
                />

                <select
                  v-model="sku2"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty2"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice2"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount2"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  v-model="sku3"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty3"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice3"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount3"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  v-model="sku4"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty4"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice4"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount4"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  v-model="sku5"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty5"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice5"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount5"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  v-model="sku6"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty6"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice6"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount6"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  v-model="sku7"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty7"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice7"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount7"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  v-model="sku8"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty8"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice8"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount8"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  v-model="sku9"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty9"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice9"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount9"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  v-model="sku10"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option value="">Choose..</option>
                  <option v-for="sku in skus" :key="sku.id" :value="sku.sku">
                    {{ sku.sku }}
                  </option>
                </select>
                <input
                  type="text"
                  v-model="qty10"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  type="text"
                  v-model="unitprice10"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="amount10"
                  class="block w-full sm:text-sm border-gray-300"
                />
              </div>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="px-4 py-5">
              <div class="grid grid-cols-4 gap-1">
                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Sub Total</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="subtotal"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >VAT</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="vat"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Bundle / Discount</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    type="text"
                    v-model="discount"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Total</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="total"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <button type="button" class="submit bg-purple-blue" @click="submit">
        Create
      </button>
      <button type="button" class="close" @click="close" ref="cancelButtonRef">
        Cancel
      </button>
    </div>
  </Model>
</template>

<script>
import Model from "../../components/containers/model/Model-7xl.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      id: "",
      date: "",
      time: "",
      customertype: "",
      customername: "",
      address: "",
      invoicetype: "",
      creditbalance: "",
      bundlecode: "",
      sku1: "",
      sku2: "",
      sku3: "",
      sku4: "",
      sku5: "",
      sku6: "",
      sku7: "",
      sku8: "",
      sku9: "",
      sku10: "",
      qty1: "",
      qty2: "",
      qty3: "",
      qty4: "",
      qty5: "",
      qty6: "",
      qty7: "",
      qty8: "",
      qty9: "",
      qty10: "",
      unitprice1: "",
      unitprice2: "",
      unitprice3: "",
      unitprice4: "",
      unitprice5: "",
      unitprice6: "",
      unitprice7: "",
      unitprice8: "",
      unitprice9: "",
      unitprice10: "",
      discount: "",
    };
  },
  components: {
    Model,
  },
  created() {
    this.$store.dispatch("getSkus");
  },
  methods: {
    submit() {
      var invoice = {
        user_id: "1",
        date: this.today,
        time: this.now,
        salesperson: this.salesperson,
        location: this.location,
        region: this.region,
        city: this.city,
        customertype: this.customertype,
        customername: this.customername,
        address: this.address,
        invoicetype: this.invoicetype,
        cnbalance: this.creditbalance,
        bundlecode: this.bundlecode,
        sku1: this.sku1,
        sku2: this.sku2,
        sku3: this.sku3,
        sku4: this.sku4,
        sku5: this.sku5,
        sku6: this.sku6,
        sku7: this.sku7,
        sku8: this.sku8,
        sku9: this.sku9,
        sku10: this.sku10,
        qty1: this.qty1,
        qty2: this.qty2,
        qty3: this.qty3,
        qty4: this.qty4,
        qty5: this.qty5,
        qty6: this.qty6,
        qty7: this.qty7,
        qty8: this.qty8,
        qty9: this.qty9,
        qty10: this.qty10,
        unitprice1: this.unitprice1,
        unitprice2: this.unitprice2,
        unitprice3: this.unitprice3,
        unitprice4: this.unitprice4,
        unitprice5: this.unitprice5,
        unitprice6: this.unitprice6,
        unitprice7: this.unitprice7,
        unitprice8: this.unitprice8,
        unitprice9: this.unitprice9,
        unitprice10: this.unitprice10,
        amount1: this.amount1,
        amount2: this.amount2,
        amount3: this.amount3,
        amount4: this.amount4,
        amount5: this.amount5,
        amount6: this.amount6,
        amount7: this.amount7,
        amount8: this.amount8,
        amount9: this.amount9,
        amount10: this.amount10,
        discount: this.discount,
        subtotal: this.subtotal,
        vat: this.vat,
        total: this.total,
      };

      console.log(invoice);
      this.$store.dispatch("salesinvoice/store", invoice).then(() => {
        this.invoice = null;
        this.$router.push({ name: "Sales" });
      });
    },
    close() {
      this.$router.push({ name: "Sales" });
    },
  },
  computed: {
    salesperson() {
      return "User 1";
    },
    location() {
      return "Dubai";
    },
    region() {
      return "UAE";
    },
    city() {
      return "Qusais";
    },
    today() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      return date;
    },
    now() {
      var today = new Date();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return time;
    },
    amount1() {
      return (Number(this.qty1) * Number(this.unitprice1)).toFixed(2);
    },
    amount2() {
      return (Number(this.qty2) * Number(this.unitprice2)).toFixed(2);
    },
    amount3() {
      return (Number(this.qty3) * Number(this.unitprice3)).toFixed(2);
    },
    amount4() {
      return (Number(this.qty4) * Number(this.unitprice4)).toFixed(2);
    },
    amount5() {
      return (Number(this.qty5) * Number(this.unitprice5)).toFixed(2);
    },
    amount6() {
      return (Number(this.qty6) * Number(this.unitprice6)).toFixed(2);
    },
    amount7() {
      return (Number(this.qty7) * Number(this.unitprice7)).toFixed(2);
    },
    amount8() {
      return (Number(this.qty8) * Number(this.unitprice8)).toFixed(2);
    },
    amount9() {
      return (Number(this.qty9) * Number(this.unitprice9)).toFixed(2);
    },
    amount10() {
      return (Number(this.qty10) * Number(this.unitprice10)).toFixed(2);
    },
    subtotal() {
      var subtotal =
        Number(this.amount1) +
        Number(this.amount2) +
        Number(this.amount3) +
        Number(this.amount4) +
        Number(this.amount5) +
        Number(this.amount6) +
        Number(this.amount7) +
        Number(this.amount8) +
        Number(this.amount9) +
        Number(this.amount10);
      return subtotal.toFixed(2);
    },
    vat() {
      return (this.subtotal * 0.05).toFixed(2);
    },
    total() {
      return (
        Number(this.subtotal) +
        Number(this.vat) -
        Number(this.discount)
      ).toFixed(2);
    },
    ...mapState(["skus"]),
  },
};
</script>

<style scoped>
.body {
  @apply bg-white;
}
.footer {
  @apply bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse;
}
.footer .submit {
  @apply w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm;
}
.footer .close {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
}
.input-text {
  @apply mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}
</style>