<template>
  <div>
    <div class="header">
      <button type="button" @click="addinvoice">Create invoice</button>
    </div>

    <div class="m-4">
      <table v-if="invoices">
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Customer name</th>
            <th>Customer Type</th>
            <th>Invoice Value</th>
            <th>Invoice type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in invoices.invoices" :key="invoice.id">
            <td>{{ invoice.id }}</td>
            <td>{{ invoice.customername }}</td>
            <td>{{ invoice.customertype }}</td>
            <td>{{ invoice.total }}</td>
            <td>{{ invoice.invoicetype }}</td>
            <td>
              <a href="#" class="text-indigo-500" @click="edit(invoice.id)"
                >Edit</a
              >
              <a href="#" class="text-red-500 ml-2" @click="destroy(invoice.id)"
                >Delete</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    this.$store.dispatch("invoices/get");
  },
  computed: mapState(["invoices"]),
  methods: {
    invoiceQty(details) {
      var qty = 0;
      for (let detail of details) {
        qty += Number(detail.qty);
      }
      return qty;
    },
    invoiceTotal(details) {
      var subtotal = 0;
      for (let detail of details) {
        subtotal += Number(detail.qty) * Number(detail.unitprice);
      }
      var total = subtotal + subtotal * 0.05;
      return total.toFixed(2);
    },
    edit(id) {
      this.$router.push({ name: "SalesShow", params: { id: id } });
    },
    destroy(id) {
      this.$store.dispatch("invoices/destroy", id);
    },
    addinvoice() {
      this.$router.push({ name: "SalesAdd" });
    },
    getDateTime(timestamp) {
      timestamp = new Date(timestamp);
      const date =
        timestamp.getFullYear() +
        "-" +
        (timestamp.getMonth() + 1) +
        "-" +
        timestamp.getDate();
      const time =
        timestamp.getHours() +
        ":" +
        timestamp.getMinutes() +
        ":" +
        timestamp.getSeconds();
      const dateTime = date + " " + time;
      return dateTime;
    },
  },
};
</script>

<style scoped>
table {
  @apply min-w-full divide-y divide-gray-200;
}
table thead {
  @apply bg-gray-50;
}
table thead tr th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider;
}
table tbody {
  @apply bg-white divide-y divide-gray-200;
}
table tbody tr td {
  @apply px-6 py-3 text-left text-sm font-medium text-gray-900 tracking-wider;
}
td.links {
  @apply inline-flex items-center space-x-4;
}
td.links a {
  @apply text-indigo-600 hover:text-indigo-900;
}
td.links form button {
  @apply text-red-600 hover:text-red-900 focus:outline-none font-medium;
}
.input-left {
  @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 rounded-none rounded-l-md sm:text-sm border-gray-300;
}
.input-right {
  @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 rounded-none rounded-r-md sm:text-sm border-gray-300;
}
.header {
  @apply flex items-center px-5 py-3 space-x-3;
}
.header button {
  @apply bg-gradient-to-r from-purple-400 to-blue-500 text-gray-200 bg-indigo-700 hover:text-white  hover:bg-indigo-600 text-sm font-medium inline-flex items-center px-3 py-2 rounded shadow;
}
</style>