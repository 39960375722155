import EventServices from '@/services/EventServices.js'

export const namespaced = true

export const state={
    requests:[],
    request:null,
    skus:[],
    details:[],
}

export const mutations={
    SET_REQUESTS(state,requests){
        state.requests=requests
    },
    SET_REQUEST(state,request){
        state.request=request
    },
    DELETE_REQUEST(state,id){
        state.requests=state.requests.filter(request=>request.id !== id)
    },
    SET_SKUS(state,skus){
        state.skus=skus
    },
    SET_DETAIL(state,details){
        state.details = details
    },
    DELETE_DETAIL(state,id){
        state.details = state.details.filter(detail=>detail.id!==id)
        console.log("Mutation received delete detail request for id : "+id)
    }
}

export const actions = {
    fetchRequests({commit}){
        EventServices.getRequests().then((response)=>{
            commit('SET_REQUESTS',response.data)
        }).catch((error)=>{
            console.log(error.message)
        })
    },
    fetchRequest({commit},id){
        return EventServices.getRequest(id).then((response)=>{
            EventServices.getRequestDetail(id).
            then((response)=>{
                //console.log("details received",response.data.details)
                commit('SET_DETAIL',response.data.details)
            }).
            catch((error)=>{
                console.log("There was an error fetching the details",error.message)
            })
            commit('SET_REQUEST',response.data)
            //console.log('received data for the id : '+id, response.data)
        }).catch((error)=>{
            console.log(error.message)
        })
    },
    setRequest({commit},request){
        return EventServices.postRequest(request).
        then((response)=>{
            commit('SET_REQUEST',response.data)
        }).
        catch((error)=>{
            console.log("There was an error posting the request",error.message)
        })
    },
    putRequest({commit},{request,id}){
        //console.log('transferred id is',id)
        return EventServices.putRequest(request,id).
        then((response)=>{
            commit('SET_REQUEST',response.data)
            //console.log('received data is ',response.data)
        }).
        catch((error)=>{
            console.log("There was an error updating the request",error.message)
        })
    },
    deleteRequest({commit},id){
        return EventServices.deleteRequest(id).
        then(()=>{
            commit('DELETE_REQUEST',id)
        }).
        catch((error)=>{
            console.log("There was an error delete the request",error.message)
        })
    },
    getSkus({commit}){
        return EventServices.getSkus().then((response)=>{
            commit('SET_SKUS',response.data)
        }).catch((error)=>{
            console.log("There was error fetching Sku's",error.message)
        })
    },
    postDetail({commit},detail){
        //console.log("Details received ",detail)
        return EventServices.postRequestDetail(detail).
        then(()=>{
            EventServices.getRequestDetail(detail.requeststockorder_id).
            then((response)=>{
                //console.log("details received",response.data.details)
                commit('SET_DETAIL',response.data.details)
            }).
            catch((error)=>{
                console.log("There was an error fetching the details",error.message)
            })
        }).
        catch((error)=>{
            console.log("Error posting request detail:",error.message)
        })
    },
    deleteDetail({commit},id){
        console.log("Request Action received delete detail request for id : "+id)
        return EventServices.deleteDetail(id).then(()=>{
            commit('DELETE_DETAIL',id)
        })
    }
}