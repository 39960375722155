<template>
  <div>
    <div class="px-5 py-3">
      <div class="space-x-3">
        <button type="button" class="btn-primary">New Return Stock</button>
      </div>
    </div>
    <h1 class="text-2xl font-bold text-gray-900">This is Stock Return Page</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btn-primary {
  @apply px-3 py-2 text-gray-200 font-medium bg-indigo-700 hover:text-white hover:bg-indigo-600 rounded focus:outline-none;
}
</style>