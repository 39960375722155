<template>
  <div class="flex items-center">
    <div class="px-5 py-3">
      <div class="space-x-3">
        <button type="button" class="btn-primary">New Request Stocks</button>
      </div>
    </div>
  </div>

  <div v-if="request">
    <ContainersForm>
      <p>
        Order id (Auto) : <span>{{ request.id }}</span>
      </p>
      <p>
        Region : <span>{{ request.region }}</span>
      </p>
      <p>
        City : <span>{{ request.city }}</span>
      </p>

      <table>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Quantity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="detail in details" :key="detail.id">
            <td>{{ getSku(detail.sku_id) }}</td>
            <td>{{ detail.qty }}</td>
            <td>
              <a
                @click="deletedetail(detail.id)"
                href="#"
                class="text-red-700 flex"
              >
                <TrashIcon
                  class="h-5 w-5 group-hover:text-indigo-400 mr-2"
                  aria-hidden="true"
                />
                <span>Delete</span></a
              >
            </td>
          </tr>
        </tbody>
      </table>

      <form action="#" @submit.prevent="add">
        <div class="flex">
          <select v-model="sku_id" class="input-left">
            <option value="">Choose..</option>
            <option v-for="sku in skus" :key="sku.id" :value="sku.id">
              {{ sku.sku }}
            </option>
          </select>
          <input type="text" v-model="qty" class="input-right" />
          <div class="ml-4">
            <button
              type="submit"
              class="bg-indigo-500 px-3 py-2 text-white rounded shadow"
            >
              Add
            </button>
          </div>
        </div>
      </form>
      <!--End of Table-->
    </ContainersForm>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ContainersForm from "@/components/containers/ContainersForm";
import { TrashIcon } from "@heroicons/vue/solid";

export default {
  data() {
    return {
      sku_id: "",
      qty: "",
    };
  },
  components: {
    ContainersForm,
    TrashIcon,
  },
  props: ["id"],
  created() {
    this.fetchRequest(this.id).then(() => {
      //console.log("Request fetched successfully", this.request);
    });
    this.getSkus().then(() => {
      //console.log("Skus fetched successfully", this.skus);
    });
  },
  computed: {
    ...mapState({
      request: (state) => state.request.request,
    }),
    ...mapGetters("user", ["userID"]),
    ...mapState("request", ["skus", "details"]),
  },
  methods: {
    deletedetail(id) {
      console.log("Method received delete detail request for id :" + id);
      this.deleteDetail(id).then(() => {
        console.log("Detail id : " + id + " has been deleted");
      });
    },
    getSku(id) {
      var sku = this.skus.find((val) => val.id == id);
      return sku.sku;
    },
    add() {
      var detail = {
        sku_id: this.sku_id,
        qty: this.qty,
        requeststockorder_id: this.request.id,
      };
      this.postDetail(detail).then(() => {
        console.log("detail successfully posted");
        console.log(this.details);
      });
    },
    ...mapActions("request", [
      "fetchRequest",
      "putRequest",
      "getSkus",
      "postDetail",
      "deleteDetail",
    ]),
  },
};
</script>

<style scoped>
p {
  @apply text-lg font-medium text-gray-500 mb-3;
}
p span {
  @apply text-gray-900;
}
table {
  @apply min-w-full divide-y divide-gray-200;
}
table thead {
  @apply bg-gray-50;
}
table thead tr th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider;
}
table tbody {
  @apply bg-white divide-y divide-gray-200;
}
table tbody tr td {
  @apply px-6 py-3 text-left text-sm font-medium text-gray-900 tracking-wider;
}
td.links {
  @apply inline-flex items-center space-x-4;
}
td.links a {
  @apply text-indigo-600 hover:text-indigo-900;
}
td.links form button {
  @apply text-red-600 hover:text-red-900 focus:outline-none font-medium;
}
.input-left {
  @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 rounded-none rounded-l-md sm:text-sm border-gray-300;
}
.input-right {
  @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 rounded-none rounded-r-md sm:text-sm border-gray-300;
}
</style>