<template>
  <div v-if="loggedIn">
    <App>
      <router-view />
    </App>
  </div>

  <div v-else>
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import App from "@/views/layouts/App.vue";

export default {
  components: {
    App,
  },
  methods: {
    logout() {
      this.logout();
    },
    ...mapActions("user", ["logout"]),
  },
  computed: mapGetters("user", ["loggedIn"]),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap");
html,
body {
  font-family: "Noto Sans KR", sans-serif;
}

#app {
  font-family: "Noto Sans KR", sans-serif;
}
</style>
