<template>
  <Model>
    <div v-if="!invoice" style="height: 200px">
      <div class="flex items-center justify-center">
        <h1>Loading..</h1>
      </div>
    </div>
    <div class="body" v-if="invoice">
      <div class="bg-purple-blue">
        <div class="px-3 py-2">
          <h2 class="text-xl text-white font-bold">Invoice</h2>
        </div>
      </div>
      <div class="overflow-y-auto no-scrollbar max-h-96">
        <!-- Top Sectionn -->
        <div class="md:grid md:grid-cols-4 md:gap-6">
          <div class="md:col-span-2">
            <div class="px-4 py-5">
              <div class="grid grid-cols-4 gap-1">
                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Invoice ID(Auto)</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.id"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Date</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.date"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Time</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.time"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Salesperson</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.salesperson"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Location</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.location"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Region</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.region"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >City</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.city"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="px-4 py-5">
              <div class="grid grid-cols-4 gap-1">
                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Customer Type</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <select
                    disabled
                    :value="invoice.customertype"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="Individual">Individual</option>
                    <option value="Trader">Trader</option>
                  </select>
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Customer Name</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.customername"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Address</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.address"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Invoice Type</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <select
                    disabled
                    :value="invoice.invoicetype"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="Cash">Cash</option>
                    <option value="Account">Account</option>
                  </select>
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Credit balance</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.creditbalance"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Bandle Code</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.bundlecode"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Bottom Section -->
        <div class="md:grid md:grid-cols-4 md:gap-6">
          <div class="md:col-span-2">
            <div class="px-4 py-5">
              <div
                class="grid grid-flow-row grid-cols-4 grid-rows-1 data-table"
              >
                <input
                  disabled
                  type="text"
                  :value="SKU"
                  class="focus:ring-indigo-500 text-gray-700 font-bold focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border-r-0 bg-indigo-100"
                />
                <input
                  disabled
                  type="text"
                  :value="Quntity"
                  class="focus:ring-indigo-500 text-gray-700 font-bold focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border-r-0 bg-indigo-100"
                />
                <input
                  disabled
                  type="text"
                  value="Unitprice"
                  class="focus:ring-indigo-500 text-gray-700 font-bold focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border-r-0 bg-indigo-100"
                />
                <input
                  disabled
                  type="text"
                  :value="Amount"
                  class="focus:ring-indigo-500 text-gray-700 font-bold focus:border-indigo-500 block w-full sm:text-sm border-gray-300 bg-indigo-100"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku1">{{ invoice.sku1 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty1"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice1"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount1"
                  class="block w-full sm:text-sm border-gray-300"
                />

                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku2">{{ invoice.sku2 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty2"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice2"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount2"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku3">{{ invoice.sku3 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty3"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice3"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount3"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku4">{{ invoice.sku4 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty4"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice4"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount4"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku5">{{ invoice.sku5 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty5"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice5"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount5"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku6">{{ invoice.sku6 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty6"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice6"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount6"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku7">{{ invoice.sku7 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty7"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice7"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount7"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku8">{{ invoice.sku8 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty8"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice8"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount8"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku9">{{ invoice.sku9 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty9"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice9"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount9"
                  class="block w-full sm:text-sm border-gray-300"
                />
                <select
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                >
                  <option :value="invoice.sku10">{{ invoice.sku10 }}</option>
                </select>
                <input
                  disabled
                  type="text"
                  :value="invoice.qty10"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.unitprice10"
                  class="block w-full sm:text-sm border-gray-300 border-r-0"
                />
                <input
                  disabled
                  type="text"
                  :value="invoice.amount10"
                  class="block w-full sm:text-sm border-gray-300"
                />
              </div>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="px-4 py-5">
              <div class="grid grid-cols-4 gap-1">
                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Sub Total</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.subtotal"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >VAT</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.vat"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Bundle / Discount</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.discount"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  class="col-span-4 sm:col-span-1 md:flex md:items-center md:justify-center"
                >
                  <div class="bg-indigo-100 rounded shadow w-full">
                    <div class="px-3 py-2">
                      <label
                        for="customername"
                        class="text-gray-700 font-medium block text-sm"
                        >Total</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-4 sm:col-span-3">
                  <input
                    disabled
                    type="text"
                    :value="invoice.total"
                    class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <button type="button" class="submit bg-purple-blue" @click="submit">
        Invoice
      </button>
      <button type="button" class="close" @click="close" ref="cancelButtonRef">
        Cancel
      </button>
    </div>
  </Model>
</template>

<script>
import Model from "../../components/containers/model/Model-7xl.vue";
import { mapState } from "vuex";

export default {
  props: ["id"],
  components: {
    Model,
  },
  created() {
    this.$store.dispatch("salesinvoice/show", this.id).then(() => {
      console.log("invoice fetched successfully");
      //console.log(this.invoice.sku1);
    });
  },
  methods: {
    submit() {
      alert("Print invoice will be implemented soon");
    },
    close() {
      this.$router.push({ name: "Sales" });
    },
  },
  computed: {
    ...mapState(["skus"]),
    ...mapState("salesinvoice", ["invoice"]),
  },
};
</script>

<style scoped>
.body {
  @apply bg-white;
}
.footer {
  @apply bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse;
}
.footer .submit {
  @apply w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm;
}
.footer .close {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
}
.input-text {
  @apply mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}
</style>