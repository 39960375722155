<template>
  <div>
    <canvas id="radar-chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import radarChartData from "@/components/charts/radar/radar-data.js";

export default {
  name: "RadarChart",
  data() {
    return {
      radarChartData: radarChartData,
    };
  },
  mounted() {
    const ctx = document.getElementById("radar-chart");
    new Chart(ctx, this.radarChartData);
  },
};
</script>

<style>
</style>