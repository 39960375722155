<template>
  <div class="mt-4">
    <div class="max-w-2xl mx-auto">
      <div class="bg-white rounded shadow">
        <div class="px-5 py-4">
          <div class="container-form">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>