import EventServices from '@/services/EventServices.js'
export const namespaced = true

export const state ={
    invoices:[],
    invoice:null,
}

export const mutations={
    SET_INVOICES(state,invoices){
        state.invoices = invoices
    },
    ADD_INVOICE(state,invoice){
        state.invoices.push(invoice)
    },
    SET_INVOICE(state,invoice){
        state.invoice=invoice
    },
    DELETE_INVOICE(state,id){
        state.invoices = state.invoices.filter(invoice=>invoice.id!==id)
    },
}

export const actions={
    get({commit}){
        return EventServices.api().get('/salesinvoices').then((response)=>{
            commit('SET_INVOICES',response.data)
        })
    },
    store({commit},invoice){
        return EventServices.api().post('/salesinvoicesnew/',invoice).then((response)=>{
            commit('ADD_INVOICE',response.data)
            commit('SET_INVOICE',response.data)
        })
    },
    show({commit},id){
        commit('SET_INVOICE',null)
        return EventServices.api().get('/salesinvoices/'+id).then((response)=>{
            commit('SET_INVOICE',response.data)
        })
    },
    destroy({commit},id){
        return EventServices.api().delete('/salesinvoices/'+id).then(()=>{
            commit('DELETE_INVOICE',id)
        })
    },
}

export const getters = {

}