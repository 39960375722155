import EventServices from '@/services/EventServices.js'
export const namespaced = true

export const state ={
    invoice:null,
    details:[],
    detail:null,
}

export const mutations={
    SET_INVOICE(state,invoice){
        state.invoice = invoice
    },
    SET_DETAILS(state,details){
        state.details = details
    },
    ADD_DETAIL(state,detail){
        state.invoice.details.push(detail)
    },
    SET_DETAIL(state,detail){
        state.detail=detail
    },
    DELETE_DETAIL(state,id){
        state.invoice.details = state.invoice.details.filter(detail=>detail.id!==id)
    },
}

export const actions={
    get({commit},salesinvoice_id){
        commit('SET_INVOICE',null)
        return EventServices.api().get('/salesinvoices/details/'+salesinvoice_id).then((response)=>{
            commit('SET_INVOICE',response.data)
        })
    },
    store({commit},detail){
        return EventServices.api().post('/salesinvoicedetails',detail).then((response)=>{
            commit('ADD_DETAIL',response.data)
        })
    },
    show({commit},id){
        return EventServices.api().get('/salesinvoicedetails/'+id).then((response)=>{
            commit('SET_DETAIL',response.data)
        })
    },
    destroy({commit},id){
        return EventServices.api().delete('/salesinvoicedetails/'+id).then(()=>{
            commit('DELETE_DETAIL',id)
        })
    },
}

export const getters = {

}