<template>
  <div>
    <canvas id="polar-chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import polarChartData from "@/components/charts/polar/polar-data.js";

export default {
  name: "PolarChart",
  data() {
    return {
      polarChartData: polarChartData,
    };
  },
  mounted() {
    const ctx = document.getElementById("polar-chart");
    new Chart(ctx, this.polarChartData);
  },
};
</script>

<style>
</style>