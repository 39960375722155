import axios from 'axios'

const apiClient = axios.create({
  baseURL: `https://api-nav.gsa-analytics.com/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
})

export default {
    setToken(token){
      apiClient.defaults.headers.common['Authorization']=`Bearer ${
        token
      }`
    },
    loginUser(user){
      return apiClient.post('/users/login',user)
    },
    getRequests(){
      return apiClient.get('/requeststockorders/')
    },
    getRequest(id){
      return apiClient.get('/requeststockorders/'+id)
    },
    postRequest(request){
      return apiClient.post('/requeststockorders/',request)
    },
    putRequest(request,id){
      return apiClient.put('/requeststockorders/'+id,request)
    },
    deleteRequest(id){
      return apiClient.delete('/requeststockorders/'+id)
    },
    getSkus(){
      return apiClient.get('/skus')
    },
    postRequestDetail(detail){
      return apiClient.post('/requeststockorderdetails/',detail)
    },
    getRequestDetail(id){
      return apiClient.get('/requeststocks/'+id)
    },
    deleteDetail(id){
      console.log("Event services received delete request detail for id : "+id)
      return apiClient.delete('/requeststockorderdetails/'+id)
    },
    api(){
      return apiClient;
    }
  }