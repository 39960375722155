<template>
  <div>
    <canvas id="dognut-chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import dognutChartData from "@/components/charts/dognut/dognut-data.js";

export default {
  name: "DognutChart",
  data() {
    return {
      dognutChartData: dognutChartData,
    };
  },
  mounted() {
    const ctx = document.getElementById("dognut-chart");
    new Chart(ctx, this.dognutChartData);
  },
};
</script>

<style>
</style>